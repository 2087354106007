export interface SiccoOverviewData {
  currRetailMonth: string;
  currRetailSubmitDate: string;
  currRetailTotal: string;
  currPreCheckTotal: string;
  currMdfPreCheckTotal: string;
  lastRetailMonth: string;
  lastRetailSubmitDate: string;
  lastRetailTotal: string;
  lastPreCheckTotal: string;
  lastMdfPreCheckTotal: string;
}

export interface SiccoTableData {
  approlStatus: string;
  customerName: string;
  id: number;
  model: string;
  retailDate: string;
  specialUsage: string;
  checkStatus: string;
  usage: string;
  vinNo: string;
  invoicePath: string;
  licensePath: string;
  dealerSwtCode: string;
  dealerCofficoCode: string;
  dealerNameCn: string;
  region: string;
  entity: string;
  brand: string;
  dealerCode: string;
}
export interface SiccoBaseTable {
  content: SiccoTableData[];
  totalElements: number;
  size: number;
}
export interface SiccoRetailHistoryData {
  id: number;
  invoiceBillingDate: string;
  invoiceDate: string;
  licenseBillingDate: string;
  licenseDate: string;
  saleFlag: string;
  specialUsage: string;
  usage: string;
  vinNo: string;
}

export interface SiccoStatusData {
  msg: string;
  code: string;
}
interface FailTable {
  replyAt: string;
  id: number;
  comments: string;
}
export interface SiccoBasicInfo {
  approlStatus: string;
  attachFiles: [
    {
      preSignedUrl: string;
      name: string;
      base64: string;
      docName: string;
      docType: string;
    }
  ];
  failReasonReplyList: FailTable[];
  checkStatus: string;
  customerName: string;
  id: number;
  invoiceDate: string;
  licenseDate: string;
  model: string;
  price: string;
  sysAutoCheckFailReason: string;
  usage: string;
  vinNo: string;
}
export interface DealerInfoData {
  dealerCoficoCode: string;
  dealerSWTCode: string;
  dealerNameCn: string;
  dealerNameEn: string;
  mbPocc: string;
  eqPocc: string;
  denzaPocc: string;
  cpoPocc: string;
  phevPocc: string;
  amgPocc: string;
  vanPocc: string;
}
export const EnumApprovalStatus = {
  '0000': '待提交',
  '0001': '延迟待提交',
  '1111': '已过期',
  '2222': '已提交',
  '3333': '已过期'
}

export const EnumCheckStatus = {
  '0000': '无',
  '1111': '系统检查中',
  '1112': '待审核',
  '1113': '修改待审核',
  '2222': '有效',
  '3333': '无效'
}
interface SelectArray {
  code: string;
  msg: string;
}
export interface SelectData {
  typeClass: SelectArray[];
  modelYear: SelectArray[];
  powerTrain: SelectArray[];
  salesRegion: SelectArray[];
}

export interface ResponseData {
  code: string;
  data: string;
  message: string;
}